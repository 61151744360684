<!--门店提现审核-->
<template>
  <div id="storeWithdrawals">
    <div class="table-search">
      <div class="search-L">
        <el-select
          v-model="tableParams.auditsStatus"
          placeholder="请选择审核状态"
          clearable
          @change="reload"
          class="mr15"
        >
          <el-option
            v-for="item in ObjToOpt(auditsStatusObj)"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <el-date-picker
        v-model="dateToDate"
        type="daterange"
        align="center"
        unlink-panels
        range-separator="~"
        start-placeholder="结算开始日期"
        end-placeholder="结算结束日期"
        :picker-options="pickerOptions"
        @change="goSelectDate()"
        clearable
        value-format="yyyy-MM-dd"
      >
      </el-date-picker>
    </div>
    <!-- 表格 -->
    <el-table
      :data="tableData"
      border
      stripe
      :header-cell-style="{
        background: '#FAFAFA',
        color: '#000000',
      }"
    >
      <el-table-column
        label="审核发起时间"
        align="center"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          <span>{{ scope.row.merchantName || "-" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="申请门店/商户" align="center" show-overflow-tooltip>
        <template slot-scope="scope">
          <span>{{ scope.row.shopName || "-" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="申请人" align="center" show-overflow-tooltip>
        <template slot-scope="scope">
          <span>{{ scope.row.serviceName || "-" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="提现金额" align="center" show-overflow-tooltip>
        <template slot-scope="scope">
          <span>{{ scope.row.roomCount || "0" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="审核状态" align="center" show-overflow-tooltip>
        <template slot-scope="scope">
          <span>{{ scope.row.roomCount || "0" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="审批人" align="center" show-overflow-tooltip>
        <template slot-scope="scope">
          <span>{{ scope.row.roomCount || "0" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="审批时间" align="center" show-overflow-tooltip>
        <template slot-scope="scope">
          <span>{{ scope.row.roomCount || "0" }}</span>
        </template>
      </el-table-column>
      <el-table-column width="200" label="操作" align="center">
        <template slot-scope="scope">
          <el-button
            type="text"
            size="medium"
            @click.stop="goClick(1, scope.row)"
            >通过</el-button
          >
          <el-button
            type="text"
            size="medium"
            @click.stop="goClick(2, scope.row)"
            >拒绝</el-button
          >
          <el-button
            type="text"
            size="medium"
            @click.stop="goClick(3, scope.row)"
            >查看原因</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="table-footer">
      <el-pagination
        layout="total,sizes, prev, pager, next,jumper"
        background
        :total="total"
        :page-size="tableParams.size"
        @size-change="onSizeChange"
        :page-sizes="[10, 20, 50, 100]"
        :current-page.sync="currentPage"
        @current-change="onCurrentPage"
      >
      </el-pagination>
    </div>
        <!--拒绝原因-->
        <el-dialog
      :visible.sync="reasonShow"
      title="拒绝原因"
      :close-on-click-modal="false"
      width="500px"
      custom-class="add-dialog"
    >
      <el-input
        type="textarea"
        :rows="5"
        placeholder="请输入拒绝原因"
        v-model="textarea"
        :disabled="!isCanInput"
      >
      </el-input>
      <div slot="footer" class="dialog-footer" v-if="isCanInput">
        <el-button @click="reasonShow = false">取 消</el-button>
        <el-button type="primary" @click="onSubmit()">保存</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { auditsStatusObj,pickerOptions } from "@/db/objs";
import { ObjToOpt, } from "@/utils/utils";

export default {
  name: "StoreWithdrawals",
  data() {
    return {
      ObjToOpt,pickerOptions,
      auditsStatusObj,
      tableParams: {
        auditsStatus: "",
        startTime: "",
        endTime: "",
        page: 1,
        size: 10,
      },
      dateToDate: [],
      tableData: [{ merchantName: 1 }],
      total: 0,
      currentPage: 1,
      textarea: "",
      reasonShow: false,
      isCanInput: true,
      reasonShow: false,
    };
  },
  methods: {
    // 【监听】表格重载
    reload() {
      this.tableParams.page = 1;
      this.currentPage = 1;
    },
    // 【监听】表格分页点击
    onCurrentPage(page) {
      this.tableParams.page = page;
    },

    // 【监听】表格条数点击
    onSizeChange(size) {
      this.tableParams.size = size;
      this.reload();
    },
    // 点击
    goClick(type, row) {
      switch (type) {
        case 1:
          this.$confirm("是否确定通过审核?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }).then(() => {
            this.$message({
              type: "success",
              message: "审核通过!",
            });
          });
          break;
        case 2:
          this.isCanInput = true;
          this.reasonShow = true;
          break;
        case 3:
          this.isCanInput = false;
          this.reasonShow = true;
          break;
      }
    },
    // 选择日期
    goSelectDate() {
      if (this.dateToDate) {
        this.tableParams.startTime = this.dateToDate[0];
        this.endTime.startTime = this.dateToDate[1];
      } else {
        this.tableParams.startTime = "";
        this.endTime.startTime = "";
      }
    },
  },
};
</script>
<style lang="scss" scoped>
#storeWithdrawals {
  padding: 0.15rem;
  box-sizing: border-box;
  .table-search {
    display: flex;
    margin-bottom: 0.15rem;
    .search-L {
      display: flex;
    }
  }
  .table-footer {
    margin-top: 0.1rem;
    display: flex;
    justify-content: flex-end;
  }
}
</style>